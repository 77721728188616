body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-image: repeating-linear-gradient(135deg, rgb(26,31,54) 0px,rgb(26,31,54) 12px,transparent 13px),repeating-linear-gradient(217deg, rgb(26,31,54) 0px,rgb(26,31,54) 12px,transparent 13px),linear-gradient(90deg, hsla(205,0%,61%,1),hsla(190,0%,61%,1));
  color: #f5f5f5;
  line-height: 1.5;
}

.app {
  max-width: 90em;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Landing Page Styles */
.name {
  font-size: 4rem;
  color: #B4C3D0;
  margin-bottom: 1rem;
}

.landing-page-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.landing-page-link {
  font-size: 2rem;
  color: #8899A6;
  text-decoration: none;
  transition: color 0.3s ease;
}

.landing-page-link:hover {
  color: #B4C3D0;
}

/* Projects Page Styles */
.projects-page {
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
}

.projects-page header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.home-link {
  font-size: 1rem;
  color: #8899A6;
  text-decoration: none;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.home-link:hover {
  color: #B4C3D0;
}

.runtime-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.runtime-card {
  background-color: #26304E;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.runtime-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.runtime-card h2 {
  margin-top: 0;
  color: #B4C3D0;
}

.runtime-card p {
  margin: 0.5rem 0;
}

.runtime-card strong {
  color: #f5f5f5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .name {
    font-size: 3rem;
  }

  .projects-link {
    font-size: 1.5rem;
  }

  .projects-page header {
    flex-direction: column;
    align-items: flex-start;
  }

  .home-link {
    margin-top: 1rem;
  }

  .runtime-info {
    grid-template-columns: 1fr;
  }
}

/* Footer Styles */
footer {
  margin-top: auto;
  padding: 1rem;
  text-align: center;
}