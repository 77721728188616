.projects-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .home-link {
    text-decoration: none;
    color: #B4C3D0;
    font-size: 1rem;
  }
  
  .python-runtime-display {
    background-color: #26304E;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .python-runtime-display h2 {
    color: #B4C3D0;
    margin-bottom: 1rem;
  }
  
  .version-selector {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
  
  .version-selector button {
    background-color: #1A1F36;
    color: #B4C3D0;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .version-selector button.active {
    background-color: #3A4B7C;
  }
  
  .summary-card,
  .region-card {
    background-color: #1A1F36;
    border-radius: 4px;
    padding: 1rem;
    color: #B4C3D0;
    font-size: 0.875rem;
    line-height: 1.4;
  }
  
  .summary-card {
    margin-bottom: 1rem;
  }
  
  .summary-card h3,
  .region-card h4 {
    color: #FFF;
    margin-bottom: 0.75rem;
    border-bottom: 1px solid #3A4B7C;
    padding-bottom: 0.5rem;
  }
  
  .summary-card p,
  .region-card p {
    margin: 0.4rem 0;
  }
  
  .summary-card strong,
  .region-card strong {
    color: #FFD700;
    margin-right: 0.25rem;
  }
  
  .region-category {
    margin-top: 1rem;
  }
  
  .category-header {
    color: #FFF;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #3A4B7C;
    padding-bottom: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .category-header:hover {
    background-color: #3A4B7C;
  }
  
  .toggle-icon {
    font-size: 0.8em;
    transition: transform 0.3s ease;
  }
  
  .region-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @media (max-width: 768px) {
    .region-grid {
      grid-template-columns: 1fr;
    }
    
    .summary-card,
    .region-card {
      font-size: 0.8rem;
    }
  }
  
  /* Existing styles for other components */
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .project-card {
    background-color: #26304E;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .project-card h2 {
    margin-top: 0;
    color: #B4C3D0;
  }
  
  .tech-stack {
    margin-top: 1rem;
  }
  
  .tech-tag {
    display: inline-block;
    background-color: #1A1F36;
    color: #B4C3D0;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }